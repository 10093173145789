import React, { useCallback } from 'react'
import * as MUI from '@material-ui/core'

import { useFetchWithAuth } from '../../state/AuthContext'
import { useDeviceList } from '../../state/DeviceListContext'
import { DeviceUpdateResult } from '../../state/DeviceModel'
import { sendToast } from '../Toast'

import './DeviceMenu.scss'

// API Endpoints
const APIRoot          = process.env.REACT_APP_APIROOT;
const APIKey           = process.env.REACT_APP_APIKEY;
const DeviceRestartUri = id => `${APIRoot}/api/DeviceRestart?deviceId=${id}&code=${APIKey}`;
const DeviceRemoveUri  = id => `${APIRoot}/api/DeviceRemove?deviceId=${id}&code=${APIKey}`;
const DeviceUpdateUri  = id => `${APIRoot}/api/DeviceUpdate?deviceId=${id}&code=${APIKey}`;

// Locale
const strings = {
  updateSoftware: "Update Software",
  restartDevice: "Restart Device",
  removeDevice: "Remove Device",
  cancel: "Cancel",
  confirmRemove: "Are you sure you want to completely remove this device?",

  formatBuild: (d: Date) => `Build: ${d.toLocaleString()}`,

  toast: (e: DeviceUpdateResult): string => {
    switch (e) {
      case DeviceUpdateResult.DeviceNotFound: 
        return "Device not found";
      case DeviceUpdateResult.DeviceOffline: 
        return "Device was offline";
      case DeviceUpdateResult.NoUpdatesAvailable: 
        return "No updates available";
      default: 
        return "Update sent";
    }
  }
};

/** Device context menu */
const DeviceMenu = ({ history, match: { params: { id } } }) => {
  const { sendContent }         = useFetchWithAuth();
  const { setDevices, devices } = useDeviceList();

  // Get matching device
  let device    = devices.find(i => i.id === id);
  let dateBuilt = strings.formatBuild(new Date(device.buildDate * 1000));

  // Update software
  const onUpdateSoftware = useCallback(async () => {
    onCancel();

    let result = await sendContent( DeviceUpdateUri(id) );
    
    // Parse result
    let updateResult = await result.json() as DeviceUpdateResult;

    // Show toast indicating command sent
    sendToast(strings.toast(updateResult));
  }, [ sendContent ]);

  // Restart device
  const onRestartDevice = useCallback(async () => {
    onCancel();

    await sendContent( DeviceRestartUri(id) );
  }, [ sendContent ]);

  // Remove device
  const onRemoveDevice = useCallback(async () => {
    if (confirm(strings.confirmRemove)) {
      setDevices({ type: "REMOVEDEVICE", id });

      onCancel();

      await sendContent( DeviceRemoveUri(id) );
    }
  }, [ sendContent ]);

  // Cancel dialog
  const onCancel = useCallback(() => {
    history.replace("/");
  }, []);

  return (
    <MUI.Dialog id="device-menu"
                open={true} 
                onClose={onCancel}>

      {/* Content */}
      <MUI.DialogContent>
        <div id="device-menu-options">
          <MUI.Button size="small" onClick={onUpdateSoftware}>
            {strings.updateSoftware}
          </MUI.Button>
          <MUI.Button size="small" onClick={onRestartDevice}>
            {strings.restartDevice}
          </MUI.Button>
          <hr />
          <MUI.Button size="small" color="secondary" onClick={onRemoveDevice}>
            {strings.removeDevice}
          </MUI.Button>
        </div>
      </MUI.DialogContent>

      <MUI.DialogContent>
        {/* Build date */}
        <div id="device-menu-built">{dateBuilt}</div>
      </MUI.DialogContent>

      {/* Actions */}
      <MUI.DialogActions>
        <MUI.Button size="small" color="primary" onClick={onCancel}>
          {strings.cancel}
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
};

export { DeviceMenu };