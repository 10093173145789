import React from 'react';
import { Route, Switch } from 'react-router'

import { useDeviceList } from '../../state/DeviceListContext';

import { DeviceList } from './DeviceList'
import { DeviceAdd, DeviceAddInstructions, DeviceAddButton } from './DeviceAdd'
import { DeviceMenu } from './DeviceMenu'
import { HeatTarget } from './HeatTarget'
import { Splash } from '../Loading'

const strings = {
  loading:   `Loading...`,
  noDevices: `No devices`
}

const Dashboard = () => {
  const { devices } = useDeviceList();

  if (!devices)
    return <Splash>{strings.loading}</Splash>
    
  return (
    <div id="dashboard">
      {/* Render the device list */}
      { devices.length
        ? <DeviceList devices={devices} />
        : <Splash animate={false}>{strings.noDevices}</Splash> }

      {/* Render the DeviceAdd button */}
      <DeviceAddButton to="/add" />
      
      <Switch>
        {/* Render the ADD component */}
        <Route path="/add/:connectid" component={DeviceAdd} />

        {/* Render the ADD instructions dialog */}
        <Route path="/add" component={DeviceAddInstructions} />

        {/* Render device menu */}
        <Route path="/menu/:id" component={DeviceMenu} />
        
        {/* Render the HEAT TARGET dialog */}
        <Route path="/set/:id" component={HeatTarget} />
      </Switch>
    </div>
  );
}

export default Dashboard;