import React, { createContext, useReducer, useContext, useEffect, useCallback } from 'react'
import * as signalR from '@aspnet/signalr'
import createPersistedState from 'use-persisted-state'

import { sleep } from '../Utility'
import { useFetchWithAuth } from './AuthContext';
import { TwinModel, DeviceListType, DeviceType, ConnectionChangedEvent, HeatTargetType, DeviceEvent, DeviceEventType } from './DeviceModel';
import { sendToast } from '../components/Toast';

// Strings
const strings = {
  toastUpdate: `Device updated`
};

// API Endpoints
const APIRoot          = process.env.REACT_APP_APIROOT;
const APIKey           = process.env.REACT_APP_APIKEY;
const DeviceListUri    = `${APIRoot}/api/DeviceList?code=${APIKey}`;
const DeviceHeatOnUri  = id => `${APIRoot}/api/DeviceHeatOn?deviceId=${id}&code=${APIKey}`;
const DeviceHeatOffUri = id => `${APIRoot}/api/DeviceHeatOff?deviceId=${id}&code=${APIKey}`;
const SignalRUri       = `${APIRoot}/api/SignalRInfo?code=${APIKey}`;

const InitialState: DeviceListType = { devices: null };

// Dispatch Command
type DeviceListContextDispatch =
    { type: "LOADDEVICES", devices: DeviceType[] }
  | { type: "ADDDEVICE", device: DeviceType }
  | { type: "REMOVEDEVICE", id: string }
  | { type: "REFRESH" }
  | { type: "TWINCHANGE", id: string, twin: TwinModel }
  | { type: "CONNSTATUS", id: string, online: boolean, at: string }
  | { type: "HEATSET", id: string, param: HeatTargetType }
  | { type: "HEATOFF", id: string }

// Temperature target threshold
const threshold = .2;

/** Retrieves cached device list */
const useCachedDeviceList = createPersistedState("device-state");

/**
 * Change a single device
 * @param state Current state
 * @param id id of device
 * @param change Change to make
 */
const changeDevice = (state: DeviceListType, id: string, change: (i: DeviceType) => DeviceType): DeviceListType =>
  ({
    ...state,
    devices: state.devices.map(i =>
      i.id === id
      ? change(i)
      : i
    )
  });

/**
 * Reducer manipulation
 * @param state Current state
 * @param action action to take
 */
const reducer = (state: DeviceListType, action: DeviceListContextDispatch): DeviceListType => {

  function cacheAndReturn<T>(cache: T) {
    localStorage.setItem("device-state", JSON.stringify(cache));
    return cache;
  }

  switch (action.type) {
    case "LOADDEVICES":
      return cacheAndReturn({ devices: action.devices });

    case "ADDDEVICE":
      return cacheAndReturn({ devices: [...state.devices, action.device ] })

    case "REFRESH":
      return cacheAndReturn({ devices: null })

    case "REMOVEDEVICE":
      return cacheAndReturn({ devices: state.devices.filter(i => i.id !== action.id) })

    case "TWINCHANGE":
      let heatTarget: HeatTargetType|null;
      let reported: { [name: string]: boolean|number|string };
      let fromDevice = false;

      if (action.twin.tags)
        heatTarget = action.twin.tags.heatTarget;
      
      if (action.twin.properties) {
        reported = action.twin.properties.reported;
        fromDevice = true;
      }

      return cacheAndReturn(changeDevice(state, action.id, i => ({ 
          ...i,
          ...reported,
          online: fromDevice ? true : i.online,
          heatTarget: heatTarget || i.heatTarget,
          lastUpdated: fromDevice ? new Date().toString() : i.lastUpdated
        })
      ))

    case "CONNSTATUS":
      return cacheAndReturn(changeDevice(state, action.id, i => 
        i.lastUpdated < action.at
        ? { 
          ...i, 
          online: action.online, 
          lastUpdated: new Date(action.at).toString()
        } 
        : i
      ))

    case "HEATSET":
      return cacheAndReturn(changeDevice(state, action.id, i => ({
          ...i,
          heatTarget: action.param
        })
      ))

    case "HEATOFF":
      return cacheAndReturn(changeDevice(state, action.id, i => ({ 
          ...i,
          heatTarget: null,
          heatOn: false,
          heatEnabled: false
        })
      ))

    default:
      return cacheAndReturn(state)
  }
}

const InitialDispatch = (_: DeviceListContextDispatch) => { }

// Device list context
const DeviceListContext = createContext<[
  DeviceListType,
  (x: DeviceListContextDispatch) => void
]>([InitialState, InitialDispatch]);

const DeviceListContextProducer = ({ children }) => (
  <DeviceListContext.Provider value={useReducer(reducer, InitialState)}>
    {children}
  </DeviceListContext.Provider>
)

/**
 * Device List
 */
const useDeviceList = () => {
  const [{ devices }, setDevices] = useContext(DeviceListContext);
  const { auth, sendContent }     = useFetchWithAuth();
  const [ cachedDeviceList ]      = useCachedDeviceList<DeviceListType>(null);

  // Fetch data & store
  const loadDevices = async () => {
    while (!devices) {
      try {
        const response = await sendContent(DeviceListUri, undefined, "GET");

        setDevices({
          type: "LOADDEVICES",
          devices: await response.json()
        });

        return;
      }
      catch (e) {
        // console.log(e);

        // TODO: Display error
        await sleep(5000);
      }
    }
  };

  useEffect(() => { loadDevices() }, [ auth ]);

  if (!devices && cachedDeviceList)
    setDevices({
      type: "LOADDEVICES",
      ...cachedDeviceList
    });

  return { 
    devices: devices,
    setDevices
  };
}

/**
 * Device SignalR Subscription
 */
const useDeviceSubscription = () => {
  const { auth, sendContent } = useFetchWithAuth();
  const [, setDevices] = useContext(DeviceListContext);

  /// Connect SignalR
  const connectSignalR = async () => {
    let getConnectionInfo = async () => {
      let response = await sendContent(SignalRUri, undefined, "GET");

      return await response.json() as {
        url: string,
        accessToken: string
      };
    };

    let connInfo = await getConnectionInfo();
    let connection = new signalR.HubConnectionBuilder()
      .withUrl(connInfo.url, {
        accessTokenFactory: () => connInfo.accessToken
      })
      .build();

    // Subscribe to twin changes
    connection.on("onTwinChange", (deviceId: string, twin: TwinModel) => {
      setDevices({ type: "TWINCHANGE", id: deviceId, twin });
    });
    
    // Subscribe to connection changes
    connection.on("onConnectionChanged", (event: ConnectionChangedEvent) => {
      const { deviceId:id, eventTime: at, eventType } = event;

      setDevices({ type: "CONNSTATUS", 
        id, at, 
        online: eventType === "Microsoft.Devices.DeviceConnected" 
             || eventType === "Microsoft.Devices.DeviceCreated" 
      })
    });

    // Subscribe to events
    connection.on("onEvent", (event: DeviceEvent) => {
      if (event.type === DeviceEventType.DeviceUpdated) {
          sendToast(strings.toastUpdate);
      }
      else
        console.log(event);
    });

    // Attempt to restart the connection onclose
    connection.onclose(async () => {
      console.log("disconnected from signalr");
      
      while (true) {
        try {
          await connectSignalR();
          break;
        }
        catch(e) {
          console.error(JSON.stringify(e));
          await sleep(5000);
        }
      }
    });

    await connection.start();
  };

  /// Disconnect SignalR
  const disconnectSignalR = () => {
    console.log("TODO: Disconnect signalr, if connected");
  };

  useEffect(() => {
    if (auth) {
      connectSignalR();
      return disconnectSignalR;
    }
  }, [ auth ]);

  return { };
}

/**
 * Use specific device
 * @param deviceId Device to use
 */
const useDevice = (deviceId: string) => {
  const { devices, setDevices } = useDeviceList();

  let device = devices
    ? devices.filter(i => i.id === deviceId)[0]
    : null;

  return { device, setDevices };
}

/**
 * Retrieve device control
 */
const useDeviceControl = () => {
  const { auth, sendContentDebounce } = useFetchWithAuth();
  const { setDevices }                = useDeviceList();

  return {

    /// Set heat target of device
    setHeatTarget: useCallback(async (id: string, target: HeatTargetType) => {
      setDevices({ type: "HEATSET", id, param: target });

      await sendContentDebounce(
        DeviceHeatOnUri(id), {
          threshold,
          ...target
        }
      );
    }, [ auth, setDevices ]),

    /// Send heat off
    setHeatOff: useCallback(async (id: string) => {
      setDevices({ type: "HEATOFF", id });

      await sendContentDebounce(DeviceHeatOffUri(id));
    }, [ auth, setDevices ])

  };
}

export default DeviceListContext;
export {
  DeviceListContextProducer,
  useDeviceControl,
  useDeviceSubscription,
  useDeviceList,
  useDevice
};