import React, { useCallback, memo } from 'react'
import { Route } from 'react-router'
import { History } from 'history'
import LongPress from 'react-longpressable'
import IconButton from '@material-ui/core/IconButton'
import ChevronUp from '@material-ui/icons/KeyboardArrowUp'
import ChevronDown from '@material-ui/icons/KeyboardArrowDown'
import StatusOn from '@material-ui/icons/CheckCircle'
import StatusHeating from '@material-ui/icons/WbSunny'
import StatusOff from '@material-ui/icons/Wifi'
import StatusUnknown from '@material-ui/icons/WifiOff'
import format from 'date-fns/format'
import sub_days from 'date-fns/sub_days'

import { useDeviceControl } from '../../state/DeviceListContext'
import { DeviceType } from '../../state/DeviceModel'
import { cToF, fToC } from '../../Utility'

import './DeviceList.scss'

const locale = {
  convertToUI:  cToF,
  convertToSvc: fToC,
  formatTemp:   t => <>{Math.round(cToF(t))} <sup>°F</sup></>
}

const strings = {
  dateFormatRecent: `[As of] ddd h:mm a`, // As of Tue 3:30 pm
  dateFormatOld:    `[As of] ddd MMM Do`, // As of Tue Mar 3rd
  updateReady:      `Update available`,
}

/**
 * Display device status icon
 */
const DeviceStatus = ({ setting = "unknown" }: { setting: "on" | "off" | "heating" | "unknown" }) => {
  const StatusIcon = () => {
    switch (setting) {
      case "on":      return <StatusOn style={{ color: "#009e0f" }} />;
      case "heating": return <StatusHeating style={{ color: "#ffd966" }} />;
      case "off":     return <StatusOff />;
      default:        return <StatusUnknown style={{ color: "gray" }} />;
    }
  }

  return <div className={"status " + setting}><StatusIcon /></div>
}

/**
 * Print out 'As of Fri 4:12 pm' date
 */
const DeviceUpdatedLabel = ({ date }: { date: string }) => {
  const dateParsed = new Date(date);
  const daysAgo    = sub_days(Date(), 5);
  const dateFormat = dateParsed > daysAgo
    ? strings.dateFormatRecent
    : strings.dateFormatOld

  return <div className="last-update">{format(dateParsed, dateFormat)}</div>
}

/**
 * Display 'update available', if it is available
 */
const DeviceUpdateAvailable = ({ device: { nextVersion, buildDate } }) =>
  (buildDate < nextVersion) ? (
    <div className="update-ready">
      <div className="update-circle"></div>
      {strings.updateReady}
    </div>
  )
  : null;

/**
 * Quick ^ and v controls for device
 */
const DeviceQuickControl = ({ device, history }: { device: DeviceType, history: History }) => {
  const { setHeatOff, setHeatTarget } = useDeviceControl();
  const { id, heatEnabled, heatTarget, online, temperature } = device;

  /// Render +2 based on difference between actual temp & target temp
  const Diff = memo(() => {
    let diff;

    if (heatTarget && temperature)
      diff = Math.round(
          locale.convertToUI(heatTarget.temperature) 
        - locale.convertToUI(temperature));

    if (!diff || diff <= 0)
      return <div className="diff">&nbsp;</div>

    return <div className="diff">{`+ ${diff}`}</div>
  });

  /// Handle 'on Control' press
  const onControlPressUp = useCallback(async e => {
    e.stopPropagation();

    if (heatTarget)
      await setHeatTarget(id, { 
        ...heatTarget, 
        temperature: locale.convertToSvc(locale.convertToUI(heatTarget.temperature) +1) 
      });
    else
      history.push(`/set/${device.id}`);
  }, [ heatTarget ]);

  /// Handle 'on Control' press
  const onControlPressDown = useCallback(async e => { 
    e.stopPropagation();

    await setHeatTarget(id, { 
      ...heatTarget, 
      temperature: locale.convertToSvc(locale.convertToUI(heatTarget.temperature) -1)
    }) 
  }, [ heatTarget ]);

  /// Handle long-press up
  const onControlLongpressUp = useCallback(() =>
    history.push(`/set/${device.id}`), 
    []);

  /// Handle long-press down
  const onControlLongpressDown = useCallback(async () =>
    await setHeatOff(id), 
    []);

  // Check if down is disabled
  let downDisabled = !heatEnabled || !online;
  
  return (
    <div className="control">
      {/* Up control */}
      <LongPress onLongPress={onControlLongpressUp} longPressTime={700} disabled={!online}>
        <IconButton className="up" 
                    disabled={!online} 
                    onClick={onControlPressUp}>
          <ChevronUp />
        </IconButton>
      </LongPress>

      {/* Temperature display */}
      <Diff />

      {/* Down control */}
      <LongPress onLongPress={onControlLongpressDown} longPressTime={700} disabled={downDisabled}>
        <IconButton className="down" 
                    disabled={downDisabled} 
                    onClick={onControlPressDown}>
          <ChevronDown />
        </IconButton>
      </LongPress>
    </div>
  );
}

/// Render individual device list item
const DeviceListItem = (history: History, device: DeviceType) => {
  const { id, name, heatEnabled, heatOn, online, temperature, lastUpdated } = device;
  
  /// Convert heat flags to status
  const flagsToStatus = (): "heating" | "on" | "off" | "unknown" => {
    if (!online)     return "unknown";
    if (heatOn)      return "heating";
    if (heatEnabled) return "on";
    else             return "off";
  }
  
  /// Handle device detail navigation
  const navigateToDetail = () => history.push(`/detail/${id}`);

  /// Handle context menu
  const showDeviceMenu = e => {
    e.preventDefault();
    history.push(`/menu/${id}`);
  }

  return (
    <li key={id} className="item" 
      onClick={navigateToDetail}
      onContextMenu={showDeviceMenu}>
      <DeviceStatus setting={flagsToStatus()} />
      <div className="name">{name}</div>
      <div className="detail">
        <DeviceUpdatedLabel date={lastUpdated} />
        <DeviceUpdateAvailable device={device} />
      </div>
      <div className="temp">{temperature ? locale.formatTemp(temperature) : ``}</div>
      <DeviceQuickControl device={device} history={history} />
    </li>
  )
};

/**
 * Render list of devices
 */
const DeviceList = ({ devices }: { devices: DeviceType[] }) =>
  <>
    <Route render={({ history }) => (
      <ul id="device-list">{devices.map(DeviceListItem.bind(null, history))}</ul> 
    )} />
  </>;

export {
  DeviceList
}