import { loginPromise, sendContent } from "../state/AuthContext";

const PushAppServerKey = process.env.REACT_APP_PUSHKEY;

const APIRoot     = process.env.REACT_APP_APIROOT;
const APIKey      = process.env.REACT_APP_APIKEY;
const PushInfoUri = `${APIRoot}/api/PushInfo?code=${APIKey}`;

/**
 * Request notification permission from user
 */
const checkPermission = () => {
  if (Notification.permission === "default")
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission(result =>
        resolve(result)
      );

      if (permissionResult)
        permissionResult.then(resolve, reject);
    })
    .then(result => result === "granted");

  else
    return Promise.resolve(false);
}

/**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vapid key
 */
const urlBase64ToUint8Array = (base64String: string) => {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

/**
 * Subscribe the user to push notifications
 * @param registration
 */
const subscribeForPush = async (registration?: ServiceWorkerRegistration) => {
  if (Notification.permission !== "granted")
    return;

  if (!registration)
    registration = await navigator.serviceWorker.getRegistration();

  if (registration) {
    let tokenId = await loginPromise;

    let subscription = await registration.pushManager.subscribe({
      applicationServerKey: urlBase64ToUint8Array(PushAppServerKey),
      userVisibleOnly: true
    });

    // POST to API
    await sendContent(tokenId, PushInfoUri, subscription.toJSON());
  }
}

export {
  checkPermission,
  subscribeForPush
}