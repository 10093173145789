import { useState, useEffect, useContext } from "react";
import createPersistedState from 'use-persisted-state';

import AuthContext from "../../state/AuthContext";
import { sleep } from "../../Utility";

const APIRoot = process.env.REACT_APP_APIROOT;

/** Cached auth state */
const useCachedAuth = createPersistedState("auth-state");

/**
 * Enable the google API
 */
const useGoogleApi = (params: gapi.auth2.ClientConfig) => {
  const [ api, setApi ] = useState<gapi.auth2.GoogleAuth>(null);

  const onGoogleAPILoaded = () =>
    gapi.load("auth2", () => {
      let authInstance = gapi.auth2.getAuthInstance();

      if (authInstance)
        setApi(authInstance);
      else
        gapi.auth2.init(params).then(
          ()  => setApi(gapi.auth2.getAuthInstance()),
          err => console.error(err)
        );
    });

  useEffect(() => {
    let script    = document.createElement("script");
    script.src    = "https://apis.google.com/js/api:client.js";
    script.onload = onGoogleAPILoaded;
    
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return api;
}

/**
 * Authenticate with API
 * @param {*} id_token 
 */
const authenticateWithAPI = async (id_token: string, expires_in: number) => {
  let response = await fetch(`${APIRoot}/.auth/login/google`, {
    method: "POST",
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ id_token })
  });

  let { authenticationToken } = await response.json();

  return { tokenId: authenticationToken, expires_in };
}

/**
 * Use google user
 */
const useAPILogin = (params: gapi.auth2.ClientConfig) => {
  const [, dispatch]                  = useContext(AuthContext);
  const api                           = useGoogleApi(params);
  const [ cachedAuth, setCachedAuth ] = useCachedAuth<{tokenId: string, expires_at: number}>(null);

  /**
   * Check cache for auth
   */
  const checkCache = () => {
    let now = new Date().getTime();

    if (cachedAuth && cachedAuth.expires_at > now)
      return { 
        tokenId: cachedAuth.tokenId, 
        expires_in: cachedAuth.expires_at - now 
      };
    else
      return null;
  }

  /**
   * Login with the input google auth response
   */
  const loginWithGoogle = async (response: gapi.auth2.AuthResponse) => {
    const { id_token, expires_in } = response;
    
    let auth = checkCache();
    if (!auth) {
      auth = await authenticateWithAPI(id_token, expires_in);

      setCachedAuth({ 
        tokenId: auth.tokenId, 
        expires_at: new Date().getTime() + (expires_in * 1000) 
      });
    }

    dispatch({ type: "LOGIN", tokenId: auth.tokenId, expires_in });

    // Sleep until API expires, then retrieve a new API token
    await sleep((expires_in - 30) * 1000);

    console.info("Re-retrieving API token");
    loginWithGoogle( await api.currentUser.get().reloadAuthResponse() );
  };

  return {
    /** Check if API is loaded */
    isAPILoaded: api !== null,

    /** Get auth token */
    isSignedIn: api && api.isSignedIn.get(),

    /** Sign in user */
    signIn: async () => {
      if (api.isSignedIn.get())
        await loginWithGoogle(api.currentUser.get().getAuthResponse());

      else {
        const result = await api.signIn();

        await loginWithGoogle(result.getAuthResponse());
      }
    }
  }
}

export {
  useAPILogin
}