export type DeviceType = {
  id: string
  name: string
  nextVersion: number | null
  chipId: number
  model: string
  buildDate: number
  temperature: number | null
  localIP: string
  online: boolean
  heatEnabled: boolean
  heatOn: boolean
  heatTarget: HeatTargetType | null
  eventLogs?: {
    [date: string]: string
  }
  lastUpdated: string | null
}

export type HeatTargetType = {
  temperature: number
  holdForMinutes: number | null
}

export type DeviceListType = { 
  devices: DeviceType[] 
}

export type TwinModel = {
  version: number
  tags?: {
    name: string
    userId: string
    heatTarget?: HeatTargetType
  },
  properties?: {
    reported?: {
      [name: string]: boolean|number|string
    }
  }
}

export type ConnectionChangedEvent = {
  deviceId: string
  eventTime: string
  eventType: "Microsoft.Devices.DeviceConnected" 
           | "Microsoft.Devices.DeviceDisconnected"
           | "Microsoft.Devices.DeviceCreated"
           | "Microsoft.Devices.DeviceDeleted"
}

export type DeviceEvent = {
  deviceId: string
  enqueued: string
  type: DeviceEventType
}

export enum DeviceEventType {
  TemperatureGoalMet    = 0,
  HeatTurnedOff         = 1,
  DeviceRestarted       = 2,
  DeviceUpdated         = 3,
  DeviceUpdateAvailable = 4
}

export enum LogCategory {
  Event,
  Twin
}

export type LogTwin = {
  category: LogCategory.Twin
  deviceId: string
  enqueued: number
  target?: number
  actual?: number
}

export type LogEvent = {
  category: LogCategory.Event
  deviceId: string
  enqueued: number
  type: DeviceEventType
}

export type Log = LogEvent | LogTwin;

export enum DeviceUpdateResult {
  OK,
  DeviceNotFound,
  NoUpdatesAvailable,
  DeviceOffline
}