import React from 'react'

import './Loading.scss'

const Splash = ({ children, animate=true }) => {
  return (
    <div id="splash">
      <div id="splash-logo" className={ animate ? " animate": "" }></div>
      <div id="splash-info">{children}</div>
    </div>
  );
};

export {
  Splash
};