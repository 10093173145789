import React from 'react'
import { render } from 'react-dom'

import './index.scss'

import App from './App'
import * as serviceWorker from './registerSw'

// Render application
render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'production')
  serviceWorker.register();
else
  serviceWorker.unregister();
