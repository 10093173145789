import React, { useState, memo, useCallback } from 'react'
import * as MUI from '@material-ui/core'
import Slider from '@material-ui/lab/Slider';

import { useDevice, useDeviceControl } from '../../state/DeviceListContext';
import { HeatTargetType } from '../../state/DeviceModel';
import { fToC, cToF } from '../../Utility';
import { checkPermission, subscribeForPush } from '../../push';

import './HeatTarget.scss'

const strings = {
  title: `Turn ON`,

  setDesiredTemperature: `Set the desired temperature`,
  configureTimer: `Configure timer`,
  configureTimerDirection: `Once the desired temperature is reached`,

  hold: {
     0: 'Hold until I turn OFF',
     5: 'Hold for 5 minutes',
    10: 'Hold for 10 minutes',
    30: 'Hold for 30 minutes',
    60: 'Hold for 1 hour'
  },
  set: `Set`,
  cancel: `Cancel`
}

const locale = {
  degrees: d => `${d} °F`,
  min: 25, max: 80,
  convertToUI:  cToF,
  convertToSvc: fToC,
}

/**
 * Heat Target dialog
 * @param params 
 */
const HeatTarget = ({ history, match: { params: { id } } }) => {
  // Find the device
  const { device }        = useDevice(id);
  const { setHeatTarget } = useDeviceControl();

  // Create a heat target
  const heatTarget: HeatTargetType = (device && device.heatTarget) || {
    temperature: device && device.temperature || 13,
    holdForMinutes: 5
  };

  // Translate the target into state
  const [ target, setTarget ]     = useState(Math.round(locale.convertToUI(heatTarget.temperature || 13)));
  const [ duration, setDuration ] = useState(heatTarget.holdForMinutes || 5);

  /**
   * Set temperature
   */
  const Temperature = 
    <div id="heattarget-slider">
      <Slider value={target} id="heattarget-slider-touch"
              onChange={(_, v) => setTarget(v)}
              min={locale.min} max={locale.max} step={1} />
      <span id="heattarget-slider-num">{locale.degrees(target)}</span>
    </div>;

  /**
   * Heat hold values
   */
  const HoldDuration = memo(() => {
    const values = [ 5, 10, 30, 60, 0 ];

    const list = values.map(v => 
      <label key={v}>
        <input type="radio" 
               name="target-duration" 
               checked={duration === v}
               onChange={setDuration.bind(null, v)} />
        {strings.hold[v]}
      </label>
    );

    return <div id="heattarget-list">{ list }</div>
  });

  /**
   * Close dialog
   */
  const close = useCallback(() => history.replace("/"), null);

  /**
   * Saves changes
   */
  const saveChanges = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    // Update heat target
    const newTarget : HeatTargetType = {
      ...heatTarget,
      temperature: locale.convertToSvc(target),
      holdForMinutes: duration === 0 ? null : duration
    };

    try {
      await setHeatTarget(id, newTarget);

      // TODO: Render intermediary prompt informing the user why they are
      //       being prompted for permission

      // Check permission & subscribe for push notifications
      checkPermission().then(result => result && subscribeForPush());

      close();
    }
    catch (e) {
      console.log(e);
      // TODO: Display error
    }
  }, [ target, duration ]);

  return (
    <MUI.Dialog id="heattarget" open={true} scroll="body">
      {/* Header */}
      <MUI.DialogTitle>{strings.title}</MUI.DialogTitle>

      {/* Content */}
      <form onSubmit={saveChanges}>
        <MUI.DialogContent id="heattarget-content">
          {/* Set desired temperature */}
          <div>
            <h3>{strings.setDesiredTemperature}</h3>
            {Temperature}
          </div>

          {/* Configure duration */}
          <div>
            <h3>{strings.configureTimer}</h3>
            <p>{strings.configureTimerDirection}</p>
            <HoldDuration />
          </div>
        </MUI.DialogContent>

        {/* Actions */}
        <MUI.DialogActions>
          <MUI.Button size="small" color="primary" onClick={close}>
            {strings.cancel}
          </MUI.Button>
          <MUI.Button size="small" color="primary" type="submit">
            {strings.set}
          </MUI.Button>
        </MUI.DialogActions>
      </form>
    </MUI.Dialog>
  );
}

export { HeatTarget }