import React, { useContext, lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { BrowserRouter as Router } from "react-router-dom";

import AuthContext, { AuthContextProducer } from './state/AuthContext'
import { DeviceListContextProducer, useDeviceSubscription } from './state/DeviceListContext'

import Layout from './components/Layout'
import Dashboard from './components/Dashboard'
import { PromptLogin } from './components/Auth'
import Toast from './components/Toast'

import './App.scss'

/**
 * Device detail
 */
const DeviceDetail = lazy(() => import('./components/Detail'));

/**
 * Lazy-loaded device detail
 */
const LazyDeviceDetail = ({ history, match }) =>
  <Suspense fallback={<></>}>
    <DeviceDetail history={history} match={match} />
  </Suspense>

/**
 * Animated transitions
 */
const PageTransitions = ({ location, children }) => {
  const normalizeKey = key => 
    (key.match("^/detail/")) ? key : "/";

  return (
    <TransitionGroup className="page">
      <CSSTransition key={normalizeKey(location)}
                     classNames="page"
                     timeout={150}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}

/**
 * App entry point
 */
const App = () => {
  let [{ isLoggedIn }] = useContext(AuthContext);
  useDeviceSubscription();

  return (
    <Layout>
      <Route render={({ location }) => (
        <PageTransitions location={location.pathname}>
          {isLoggedIn
            ? <Switch location={location}>
                <Route path="/detail/:id" exact component={LazyDeviceDetail} />
                <Route component={Dashboard} />
              </Switch>
            : <PromptLogin />
          }
        </PageTransitions>
      )} />
      <Toast />
    </Layout>
  );
}

// Connect to app context
export default () => (
  <Router>
  <AuthContextProducer>
  <DeviceListContextProducer>
    {/* Actual App */}
    <App />
  </DeviceListContextProducer>
  </AuthContextProducer>
  </Router>
);