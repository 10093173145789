import React, { useEffect, useCallback, useContext } from 'react'
import MediaQuery from 'react-responsive'
import { History } from 'history'
import { Route } from 'react-router'
import * as MUI from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'

import { useFetchWithAuth } from '../../state/AuthContext'
import DeviceListContext from '../../state/DeviceListContext'
import { sendToast } from '../Toast'
import { sleep } from '../../Utility'

import './DeviceAdd.scss'

const APIRoot     = process.env.REACT_APP_APIROOT;
const APIKey      = process.env.REACT_APP_APIKEY;
const FunctionUri = `${APIRoot}/api/DeviceProvisioning?code=${APIKey}`;

const strings = {
  instructions: {
    title: `Add a New Device`,
    message: `Go to your settings and connect to your new device ` + 
             `via WiFi to complete setup. You will be automatically ` + 
             `redirected through the process from here.`
  },
  adding: {
    message: `Adding your device...`,
    failed: `Unable to add your device, please try again`
  },
  cancel: `Cancel`
};

/**
 * Device Add Section
 * @param param0 { history }
 */
const DeviceAddInstructions = ({ history }: { history: History }) => {

  const onCancel = useCallback(() => {
    history.replace("/");
  }, []);

  useEffect(() => {
    let cancelled = false;

    async function waitForConnect() {
      // Wait for cancel OR connection change
      while (!cancelled && navigator.onLine)
        await sleep(100);

      // Wait while connection is established to device
      await sleep(5000);

      if (!cancelled)
        // Redirect user
        window.location.href = "http://192.168.1.2/";
    }

    waitForConnect();

    return () => cancelled = true;
  });

  return (
    <MUI.Dialog open={true}>
      {/* Title */}
      <MUI.DialogTitle>{strings.instructions.title}</MUI.DialogTitle>

      {/* Content */}
      <MUI.DialogContent>{strings.instructions.message}</MUI.DialogContent>

      {/* Actions */}
      <MUI.DialogActions>
        <MUI.Button size="small" color="primary" onClick={onCancel}>
          {strings.cancel}
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
};

/**
 * Device is connecting
 */
const DeviceAdd = ({ history, match: { params: { connectid } } }) => {
  // Post connectid to FunctionUri
  const { auth, sendContent } = useFetchWithAuth();
  const [, setDeviceList]     = useContext(DeviceListContext);

  useEffect(() => {
    async function sendData () {
      // Take at least 700 ms
      await sleep(700);

      // Send post
      let result = await sendContent(FunctionUri, { connectid })

      if (!result.ok)
        sendToast(strings.adding.failed);
      else {
        setDeviceList({ 
          type: "ADDDEVICE",
          device: await result.json()
        });

        history.push("/");
      }
    }
    
    sendData();
  }, [ auth ]);

  return (
    <MUI.Dialog open={true} scroll="paper">
      {/* Title */}
      <MUI.DialogTitle>{strings.instructions.title}</MUI.DialogTitle>

      {/* Content */}
      <MUI.DialogContent>{strings.adding.message}</MUI.DialogContent>
    </MUI.Dialog>
  );
}

/**
 * Add Device button
 */
const DeviceAddButton = ({ to }: { to: string }) => (
  // Render the DeviceAdd button
  <Route render={({ history }) =>
    <>
      <MediaQuery query="(max-width: 599px)">
        <MUI.IconButton id="device-add-btn" onClick={() => history.push(to)}>
          <AddCircle color="primary" />
        </MUI.IconButton>
      </MediaQuery>
      <MediaQuery query="(min-width: 600px)">
        <div id="device-add-inline-btn">
          <MUI.Button
            color="primary" variant="contained"
            onClick={() => history.push(to)}>
            {strings.instructions.title}
          </MUI.Button>
        </div>
      </MediaQuery>
    </>
  } />
)

export {
  DeviceAddInstructions,
  DeviceAdd,
  DeviceAddButton
}