import React, { createContext, useReducer, useContext } from 'react'

import { debounce } from '../Utility'

// Create a promise to be fulfilled later
let loginResolve;
let loginPromise = new Promise<string>(resolve => loginResolve = resolve);

// Context Setup
type AuthContextType = {
  isLoggedIn: boolean
  auth?: { tokenId: string, expires_in: number }
}

// Dispatch Command
type AuthContextDispatch =
  { type: "LOGIN", tokenId: string, expires_in: number }
| { type: "LOGOUT" }

/**
 * Reducer manipulation
 * @param state current state
 * @param action manipulation request
 */
const reducer = (state: AuthContextType, action: AuthContextDispatch) => {
  switch (action.type) {
    case "LOGIN":
      let { tokenId, expires_in } = action;

      
      loginResolve(tokenId);
      
      return { ...state, isLoggedIn: true, auth: { tokenId, expires_in } };

    default:
      return state;
  }
}

// Boilerplate setup
const InitialState: AuthContextType = {
  isLoggedIn: false
}

const AuthContext = createContext<[
  AuthContextType,
  (_: AuthContextDispatch) => void
]>([ InitialState,  (_: AuthContextDispatch) => { } ])

const AuthContextProducer = ({ children }) => (
  <AuthContext.Provider value={ useReducer(reducer, InitialState) }>
    {children}
  </AuthContext.Provider>
)

/**
 * Send authed content
 */
const sendContent = async (tokenId: string, uri: string, body=undefined, method="POST") =>
  await fetch(uri, {
    method,
    headers: {
      "X-ZUMO-AUTH": tokenId,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });

/**
 * Send debounced auth content
 */
const sendContentDebounce = debounce(sendContent, 1500);

/**
 * Send device command
 */
const useFetchWithAuth = () => {
  const [{ auth }] = useContext(AuthContext);

  if (auth)
    return {
      auth,

      /** Send content with API key */
      sendContent: async (uri: string, body=undefined, method="POST") =>
        await sendContent(auth.tokenId, uri, body, method),

      /** Send content with API key (debounced) */
      sendContentDebounce: async (uri: string, body=undefined, method="POST") =>
        await sendContentDebounce(auth.tokenId, uri, body, method)

    };

  else
    return {
      auth,
      sendContent: null,
      sendContentDebounce: null
    };
}

export default AuthContext
export {
  AuthContextProducer,
  useFetchWithAuth,
  sendContent,
  sendContentDebounce,
  loginPromise
}