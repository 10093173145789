import React from 'react'

import { Splash } from '../Loading'
import { useAPILogin } from './google'

import './Auth.scss'

const strings = {
  loggingIn: `Logging in...`
}

/**
 * Prompt google login
 */
const PromptLogin = () => {
  const { isAPILoaded, isSignedIn, signIn } = useAPILogin({
    client_id:     process.env.REACT_APP_GOOGLECLIENTID,
    cookie_policy: "single_host_origin",
    ux_mode:       "popup"
  })

  if (isAPILoaded) {
    if (isSignedIn)
      signIn();

    else
      return (
        <div id="auth">
          <button id="auth-button" onClick={signIn} />
        </div>
      );
  }

  return <Splash>{strings.loggingIn}</Splash>;
}

export {
  PromptLogin
}