// @ts-check

import React, { useEffect, useState, memo, useCallback } from 'react';

import './Layout.scss';

const strings = {
  title: "garageHEAT"
};

const scrollPast = 42;

/**
 * Scrolling header
 */
const ScrollHeader = () => {
  const [isPinned, setPinned] = useState(false);
  const body = document.body;

  const onScroll = useCallback(() => {
    if (window.scrollY >= scrollPast) {
      if (isPinned === false) {
        body.classList.add('pinned');
        setPinned(true);
      }
    }
    else if (isPinned === true) {
        body.classList.remove('pinned');
        setPinned(false);
    }
  }, [isPinned]);

  useEffect(() => {
    // Bind scroll
    window.addEventListener("scroll", onScroll, false);

    // Unbind scroll
    return () => window.removeEventListener("scroll", onScroll);
  })

  return (
    <header id="App-header" role="heading">
      <h1>{strings.title}</h1>
    </header>
  );
}

/**
 * Layout of app
 */
const Layout = memo(({ children }) => (
  <>
    <ScrollHeader />
    <div id="App-main" role="main">
      {children}
    </div>
  </>
));

export default Layout;