import React, { memo, useState, useEffect } from 'react'

import './Toast.scss'

let dispatchToast: null | ((_: string) => void);

/**
 * Display toast message
 * @param message 
 * @param error 
 */
const sendToast = (message: string) => {
  if (!dispatchToast)
    return;

  dispatchToast(message);

  // Clear toast after 6 seconds
  setTimeout(
    () =>  dispatchToast(""),
    6000
  );
}

/**
 * Toast container
 */
const Toast = memo(() => {
  const [ toast, setToast ] = useState("");
  
  useEffect(() => {
    dispatchToast = setToast;

    return () => dispatchToast = null;
  }, [toast, setToast]);
  
  if (toast)
    return <div className="toast-message fadeout">{toast}</div>;

  else
    return null;
});

export default Toast;
export {
  sendToast
}