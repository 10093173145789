/**
 * Sleep for ms seconds
 * @param ms milliseconds to sleep
 */
function sleep(ms: number) {
  if (ms <= 0)
    return Promise.resolve(true);
  else
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Convert celsius to fahrenheit
 * @param c Celsius
 */
function cToF (c: number) {
  return (c * 9/5) + 32
}

/**
 * Convert fahrenheit to celsius
 * @param f Fahrenheit
 */
function fToC(f: number) {
  return (f - 32) * (5/9);
}

/**
 * Dont convert, noop
 * @param t Unit operation
 */
function unit(t: number) {
  return t
}

/**
 * Group by
 * @param items
 * @param key 
 */
function groupBy<T>(items: T[], by: (i: T) => any) {
  let grouped = items.reduce(function(groups, item) {
    const val = by(item);

    let arr = groups[val];
    if (!arr)
      groups[val] = arr = [];

    arr.push(item);

    return groups;
  }, {});

  return Object.keys(grouped)
               .map(key => ({ key, values: grouped[key] }));
}

/**
 * Debounce function
 */
function debounce<T extends (...any: any[]) => void>(callback: T, timeout: number) {
  let timer;
  
  let callFunction = (...args) => {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(callback.bind(null, ...args), timeout);
  }

  return callFunction as T;
}

export {
  sleep,
  cToF,
  fToC,
  unit,
  groupBy,
  debounce
}